import { currencyCode, getLocale } from '@ecomm/utils'
import { EitherT, MaybeT, path } from '@simplisafe/ewok'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { brazeTrackCartEvent } from '../braze'
import { fbTrackAddProductsToCart } from '../facebook'
import { Ecommerce, PackageItem, TrackingData } from '../types/tracking'
import { getCommerceDataFromProduct } from './../analytics'

export const useTrackAddToCart = () => {
  const { trackEvent } = useTracking<TrackingData>()

  const trackAddToCartEvent = useCallback(
    (
      productDetail: EitherT<Error, Product> | MaybeT<Product>,
      quantity: number,
      label?: string
    ) => {
      productDetail.forEach(productDetail => {
        const productName = path(['name', getLocale()], productDetail)

        const ecomm = getCommerceDataFromProduct(productDetail, 'add', quantity)

        trackEvent({
          ecommerce: ecomm,
          event: 'addToCart'
        })

        trackEvent({
          ecommerce: ecomm,
          event: 'eec.add',
          eventAction: 'addtocart',
          eventCategory: 'eec',
          eventLabel: label ? label : productName
        })

        brazeTrackCartEvent({
          eventType: 'add_to_cart',
          productTrackingData: ecomm
        })
        ecomm.add?.products && fbTrackAddProductsToCart(ecomm.add.products)
      })
    },
    [trackEvent]
  )

  const trackAddToCartPackageWithExtrasEvent = useCallback(
    (
      pkgName: string,
      pkgSku: string,
      pkgItems: readonly PackageItem[],
      additionalItems: readonly PackageItem[],
      personalizedExtraItems: readonly PackageItem[],
      isBms: boolean,
      pkgPrice: number
    ) => {
      const brand = isBms ? 'bms' : pkgName

      const formatProduct = (prod: PackageItem) => ({
        brand,
        id: prod?.product?.sku,
        sku: prod?.product?.sku,
        name: prod?.product?.name,
        price: 0,
        quantity: prod?.quantity
      })

      const ecomm: Ecommerce = {
        add: {
          products: [
            {
              brand: brand,
              id: pkgSku,
              name: pkgName,
              price: pkgPrice,
              quantity: 1
            },
            ...pkgItems.map(formatProduct),
            ...additionalItems.map(formatProduct),
            ...personalizedExtraItems.map(formatProduct)
          ]
        },
        currencyCode
      }

      trackEvent({
        ecommerce: ecomm,
        event: 'addToCart'
      })

      trackEvent({
        ecommerce: ecomm,
        event: 'eec.add',
        eventAction: 'addtocart',
        eventCategory: 'eec',
        eventLabel: pkgName
      })

      brazeTrackCartEvent({
        eventType: 'add_to_cart',
        productTrackingData: ecomm
      })

      ecomm.add?.products &&
        fbTrackAddProductsToCart(ecomm.add.products, pkgName)
    },
    [trackEvent]
  )

  return {
    trackAddToCartEvent,
    trackAddToCartPackageWithExtrasEvent
  }
}
