import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT } from './footer_fragment'
import { footerSchema } from './footerSchema'
import { useContentful } from '@ecomm/shared-apollo'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { usePhoneAndMonitoringInNavExperience } from '@ecomm/shared-ninetailed-phone'

export const FOOTER_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query FOOTER_QUERY($preview: Boolean!, $locale: String!, $id: String!) {
    footer(id: $id, preview: $preview, locale: $locale) {
      ...footer
    }
  }
`

/**
 * ONLY FOR GATSBY TEMPLATE FILES!
 */
export const useFooterQuery = (id: string) => {
  const odmonExperienceFooterId = '5HfUvVfg5mVzZdML4GE6xe'
  const phoneInNavExperienceFooterId = '5AcrNqDwFWOpPA9TfbslCP'

  const isPhoneInNavVariant = usePhoneAndMonitoringInNavExperience().isVariant
  const isOdmonVariant = useOdmonExperience().isVariant

  const { data: defaultFooterData } = useContentful(FOOTER_QUERY, { id })
  const { data: odmonFooterData } = useContentful(FOOTER_QUERY, {
    id: odmonExperienceFooterId
  })
  const { data: phoneInNavExperienceFooterData } = useContentful(FOOTER_QUERY, {
    id: phoneInNavExperienceFooterId
  })

  const odmonFooter = isOdmonVariant ? odmonFooterData : defaultFooterData
  const footerData = isPhoneInNavVariant
    ? phoneInNavExperienceFooterData
    : odmonFooter

  return footerSchema.parse(footerData['footer'], {
    path: ['useFooterQuery', 'footer']
  })
}
