// @ts-nocheck
/*
  - To add icons, paste the svg into libs/gatsby-plugins/shared/static/icons
  - The id attribute in the svg must match the corresponding icon type defined
    here in the iconTypes array or the icon will not be displayed
*/

import React, { type FC } from 'react'

export const iconTypes = [
  'ADT',
  'AI',
  'Agent',
  'Alert',
  'Ambulance',
  'ArrowDownSolidIcon',
  'Badge',
  'BaseStation',
  'Battery',
  'BatteryRechargeable',
  'BigChevronDown',
  'Book',
  'BurningFood',
  'CameraRecording',
  'Cancel',
  'CardboardBox',
  'CaretForward',
  'Cart',
  'Caution',
  'CCAmex',
  'CCDiscover',
  'CCMaster',
  'CCVisa',
  'CellphoneIcon',
  'Chat',
  'Check',
  'CheckMark',
  'CheckMarkCircle',
  'CheckmarkDarkBlue',
  'CheckSolid',
  'ChevronDown',
  'Clock',
  'Close',
  'Contract',
  'CustomerSupport',
  'Discount',
  'Dollar',
  'DollarSigns',
  'DoorChime',
  'EmergencyDispatch',
  'ExclamationCircleOutline',
  'BaseStation',
  'FaceMatching',
  'ExclamationCircleSolid',
  'FacebookLogo',
  'FalseAlarm',
  'FieldOfView',
  'Fire',
  'Floorplan',
  'GlassDetection',
  'Hamburger',
  'HamburgerRedesigned',
  'HeadsetPerson',
  'Help',
  'HelpCircle',
  'HomeBase',
  'InformationIcon',
  'Key',
  'LinkedInLogo',
  'LocationSymbol',
  'MagicStick',
  'MedicalCross',
  'Microphone',
  'Minus',
  'Miss',
  'Multilocation',
  'Onelocation',
  'PaypalLogo',
  'MotionDetection',
  'NightVision',
  'NoDrill',
  'Pause',
  'PeelAndStick',
  'PencilAndRuler',
  'PersonRunning',
  'PersonWithHardHat',
  'Phone',
  'PhoneOutgoing',
  'PhoneRinging',
  'PinterestLogo',
  'Play',
  'PlugWire',
  'Plus',
  'PoliceCar',
  'PoliceLight',
  'PoliceLogo',
  'Pound',
  'PrivacyShutter',
  'Profile',
  'ProInstall',
  'RedSiren',
  'SearchOutlineIcon',
  'SecretAlerts',
  'SecureCheckmarkIcon',
  'SecureHome',
  'SecureHomeIcon',
  'ShieldAboveHand',
  'ShieldCheck',
  'ShippingTruck',
  'SimpleSetup',
  'SimpliCheckBlue',
  'SimpliCheckDark',
  'SimpliCheckGold',
  'SimpliSafeAppIcon',
  'SimpliSafeAppIcon',
  'SimpliSafeLogo',
  'SimpliSafeLogoBlack',
  'SimpliSafeLogoDark',
  'SimpliSafeLogoWhite',
  'Siren',
  'Spotlight',
  'SmartMotion',
  'Star',
  'Suitcase',
  'SupportIcon',
  'TwitterLogo',
  'TwoWayAudio',
  'Verification',
  'Video',
  'Warning',
  'WarningBlack',
  'WeatherResistant',
  'Wired',
  'Wireless',
  'X',
  'XmarkHollow',
  'XmarkLightGray',
  'XSolidIcon',
  'Wifi',
  'YoutubeLogo',
  'Wifi',
  'CheckCircleSolid',
  'SmallX',
  'Zoom'
] as const

export type IconTypes = (typeof iconTypes)[number]

const iconUrls: Record<IconTypes, string> = {
  ADT: '/icons/adt.svg',
  AI: '/icons/ai.svg',
  Agent: '/icons/agent.svg',
  Alert: '/icons/alert.svg',
  Ambulance: 'icons/ambulance.svg',
  ArrowDownSolidIcon: '/icons/arrow-down-solid.svg',
  Badge: '/icons/badge.svg',
  BaseStation: '/icons/base-station.svg',
  Battery: '/icons/battery.svg',
  BatteryRechargeable: '/icons/battery-rechargeable.svg',
  BigChevronDown: '/icons/big-chevron-down.svg',
  Book: '/icons/book.svg',
  BurningFood: '/icons/burning-food.svg',
  CameraRecording: '/icons/camera-recording.svg',
  Cancel: '/icons/cancel.svg',
  CardboardBox: '/icons/cardboard-box.svg',
  CaretForward: '/icons/caret-forward.svg',
  Caution: '/icons/caution.svg',
  Cart: '/icons/cart.svg',
  CCAmex: '/icons/cc-amex.svg',
  CCDiscover: '/icons/cc-discover.svg',
  CCMaster: '/icons/cc-master.svg',
  CCVisa: '/icons/cc-visa.svg',
  CellphoneIcon: '/icons/cellphone.svg',
  Chat: '/icons/chat.svg',
  CheckMark: '/icons/checkmark.svg',
  CheckMarkCircle: '/icons/checkmark-circle.svg',
  CheckmarkDarkBlue: '/icons/checkmark-dark-blue.svg',
  CheckSolid: '/icons/check-solid.svg',
  ChevronDown: '/icons/chevron-down.svg',
  Clock: '/icons/clock.svg',
  Close: '/icons/close.svg',
  Contract: '/icons/contract.svg',
  CustomerSupport: '/icons/customer-support.svg',
  Discount: '/icons/discount-icon.svg',
  Dollar: '/icons/dollar.svg',
  DollarSigns: '/icons/dollar-signs.svg',
  DoorChime: 'icons/door-chime.svg',
  EmergencyDispatch: '/icons/999-emergency-dispatch.svg',
  ExclamationCircleOutline: '/icons/exclamation-circle-outline.svg',
  FaceMatching: '/icons/face-matching.svg',
  ExclamationCircleSolid: '/icons/exclamation-circle-solid.svg',
  FacebookLogo: '/icons/facebook_logo.svg',
  FalseAlarm: '/icons/false-alarm.svg',
  FieldOfView: '/icons/field-of-view.svg',
  Fire: '/icons/fire.svg',
  Floorplan: '/icons/floorplan.svg',
  GlassDetection: '/icons/glass-detection.svg',
  Hamburger: '/icons/hamburger.svg',
  HamburgerRedesigned: '/icons/hamburger-redesigned.svg',
  HeadsetPerson: '/icons/headset-person.svg',
  Help: '/icons/help.svg',
  HelpCircle: '/icons/help-circle.svg',
  HomeBase: '/icons/home-base.svg',
  InformationIcon: '/icons/information-icon.svg',
  Key: '/icons/key.svg',
  LinkedInLogo: '/icons/linkedin_logo.svg',
  LocationSymbol: '/icons/location-symbol.svg',
  MagicStick: '/icons/magic-stick.svg',
  MedicalCross: '/icons/medical-cross.svg',
  Microphone: '/icons/microphone.svg',
  Minus: '/icons/minus.svg',
  Miss: '/icons/miss.svg',
  Multilocation: '/icons/multi-location.svg',
  Onelocation: '/icons/one-location.svg',
  PaypalLogo: '/icons/paypal-logo.svg',
  MotionDetection: '/icons/motion-detection.svg',
  NightVision: '/icons/night-vision.svg',
  NoDrill: '/icons/no-drill.svg',
  Pause: '/icons/pause.svg',
  PeelAndStick: '/icons/peel-and-stick.svg',
  PencilAndRuler: '/icons/pencil-and-ruler.svg',
  PersonRunning: '/icons/person-running.svg',
  PersonWithHardHat: '/icons/person-with-hard-hat.svg',
  Phone: '/icons/phone.svg',
  PhoneOutgoing: '/icons/phone-outgoing.svg',
  PhoneRinging: '/icons/phone-ringing.svg',
  PinterestLogo: '/icons/pinterest_logo.svg',
  Play: '/icons/play.svg',
  PlugWire: '/icons/plug-wire.svg',
  Plus: '/icons/plus.svg',
  PoliceCar: '/icons/police-car.svg',
  PoliceLight: '/icons/police-light.svg',
  PoliceLogo: '/icons/police-logo.svg',
  Pound: '/icons/pound.svg',
  PrivacyShutter: '/icons/privacy-shutter.svg',
  Profile: '/icons/profile.svg',
  ProInstall: '/icons/pro-install.svg',
  RedSiren: '/icons/red-siren.svg',
  SearchOutlineIcon: '/icons/search-outline.svg',
  SecretAlerts: '/icons/secret-alerts.svg',
  SecureCheckmarkIcon: '/icons/secure-checkmark.svg',
  SecureHome: '/icons/secure-home.svg',
  SecureHomeIcon: '/icons/secure-home.svg',
  ShieldAboveHand: '/icons/shield-above-hand.svg',
  ShieldCheck: '/icons/shield-check.svg',
  ShippingTruck: '/icons/shipping-truck.svg',
  SimpleSetup: '/icons/simple-setup.svg',
  SimpliCheckBlue: '/icons/simplicheck-blue.svg',
  SimpliCheckDark: '/icons/simplicheck-dark.svg',
  SimpliCheckGold: '/icons/simplicheck-gold.svg',
  SimpliSafeAppIcon: '/icons/simplisafe-app-icon.svg',
  SimpliSafeLogo: '/icons/simplisafe-logo.svg',
  SimpliSafeLogoBlack: '/icons/simplisafe-logo-black.svg',
  SimpliSafeLogoDark: '/icons/simplisafe-logo-dark.svg',
  SimpliSafeLogoWhite: '/icons/simplisafe-logo-white.svg',
  Siren: '/icons/siren.svg',
  Spotlight: '/icons/spotlight.svg',
  SmartMotion: '/icons/smart-motion.svg',
  Star: '/icons/star.svg',
  Suitcase: '/icons/suitcase.svg',
  SupportIcon: '/icons/support.svg',
  TwitterLogo: '/icons/twitter_logo.svg',
  TwoWayAudio: '/icons/two-way-audio.svg',
  Verification: '/icons/verification.svg',
  Video: '/icons/video.svg',
  Warning: '/icons/warning.svg',
  WarningBlack: '/icons/warning-black.svg',
  WeatherResistant: '/icons/weather-resistant.svg',
  Wired: '/icons/wired.svg',
  Wireless: '/icons/wireless.svg',
  XmarkHollow: '/icons/xmark-hollow.svg',
  XmarkLightGray: '/icons/xmark-lightgray.svg',
  XSolidIcon: '/icons/x-solid.svg',
  YoutubeLogo: '/icons/youtube_logo.svg',
  Wifi: '/icons/wifi.svg',
  CheckCircleSolid: '/icons/check-circle-solid.svg',
  SmallX: '/icons/small-x.svg',
  Zoom: '/icons/zoom.svg'
}

export type IconProps = {
  /** alt text for accessibility */
  readonly titleA11y?: string
  /**
   * To avoid icons disappearing on Safari **do not** set height or width by themselves via css
   */
  readonly className?: string
  /**
   * To avoid icons disappearing on Safari **do not** set height without also setting width
   */
  readonly height?: number | string
  /**
   * To avoid icons disappearing on Safari **do not** set width without also setting height
   */
  readonly width?: number | string
  readonly alt?: string
  readonly 'data-component'?: string

  // Technically, because this icon component is just an <svg>, it can accept basically any props in
  // SVGProps<SVGSVGElement>. However, that's a huge list of properties we'll likely never use and it seems confusing to
  // have all those props displayed as options in the IDE. If you want to pass a new SVG prop to the Icon component,
  // just add it in here & as long as it's a valid SVG property it should be passed down to the underlying <svg>.
}

const createIconComponent =
  (iconKey: IconTypes): FC<IconProps> =>
  ({ titleA11y = '', className = '', ...props }: IconProps) => {
    const url = iconUrls[iconKey]
    return (
      <svg
        role={titleA11y ? 'img' : null}
        {...props}
        aria-label={titleA11y}
        className={className}
        title={titleA11y}
      >
        <use height="100%" href={`${url}#${iconKey}`} width="100%" />
      </svg>
    )
  }

export const AI = createIconComponent('AI')
export const ADT = createIconComponent('ADT')
export const Agent = createIconComponent('Agent')
export const Alert = createIconComponent('Alert')
export const Ambulance = createIconComponent('Ambulance')
export const ArrowDownSolidIcon = createIconComponent('ArrowDownSolidIcon')
export const Badge = createIconComponent('Badge')
export const BaseStation = createIconComponent('BaseStation')
export const Battery = createIconComponent('Battery')
export const BatteryRechargeable = createIconComponent('BatteryRechargeable')
export const BigChevronDown = createIconComponent('BigChevronDown')
export const Book = createIconComponent('Book')
export const BurningFood = createIconComponent('BurningFood')
export const CameraRecording = createIconComponent('CameraRecording')
export const Cancel = createIconComponent('Cancel')
export const CardboardBox = createIconComponent('CardboardBox')
export const CaretForward = createIconComponent('CaretForward')
export const Cart = createIconComponent('Cart')
export const Caution = createIconComponent('Caution')
export const CCAmex = createIconComponent('CCAmex')
export const CCDiscover = createIconComponent('CCDiscover')
export const CCMaster = createIconComponent('CCMaster')
export const CCVisa = createIconComponent('CCVisa')
export const CellphoneIcon = createIconComponent('CellphoneIcon')
export const Chat = createIconComponent('Chat')
export const Check = createIconComponent('CheckMark')
export const CheckMark = createIconComponent('CheckMark')
export const CheckMarkCircle = createIconComponent('CheckMarkCircle')
export const CheckmarkDarkBlue = createIconComponent('CheckmarkDarkBlue')
export const CheckSolid = createIconComponent('CheckSolid')
export const ChevronDown = createIconComponent('ChevronDown')
export const Clock = createIconComponent('Clock')
export const Close = createIconComponent('Close')
export const Contract = createIconComponent('Contract')
export const CustomerSupport = createIconComponent('CustomerSupport')
export const Discount = createIconComponent('Discount')
export const Dollar = createIconComponent('Dollar')
export const DoorChime = createIconComponent('DoorChime')
export const DollarSigns = createIconComponent('DollarSigns')
export const EmergencyDispatch = createIconComponent('EmergencyDispatch')
export const ExclamationCircleOutline = createIconComponent(
  'ExclamationCircleOutline'
)
export const FaceMatching = createIconComponent('FaceMatching')
export const ExclamationCircleSolid = createIconComponent(
  'ExclamationCircleSolid'
)
export const FacebookLogo = createIconComponent('FacebookLogo')
export const FalseAlarm = createIconComponent('FalseAlarm')
export const FieldOfView = createIconComponent('FieldOfView')
export const Fire = createIconComponent('Fire')
export const Floorplan = createIconComponent('Floorplan')
export const GlassDetection = createIconComponent('GlassDetection')
export const Hamburger = createIconComponent('Hamburger')
export const HamburgerRedesigned = createIconComponent('HamburgerRedesigned')
export const HeadsetPerson = createIconComponent('HeadsetPerson')
export const Help = createIconComponent('Help')
export const HelpCircle = createIconComponent('HelpCircle')
export const HomeBase = createIconComponent('HomeBase')
export const InformationIcon = createIconComponent('InformationIcon')
export const Key = createIconComponent('Key')
export const LinkedInLogo = createIconComponent('LinkedInLogo')
export const LocationSymbol = createIconComponent('LocationSymbol')
export const MagicStick = createIconComponent('MagicStick')
export const MedicalCross = createIconComponent('MedicalCross')
export const Microphone = createIconComponent('Microphone')
export const Minus = createIconComponent('Minus')
export const Miss = createIconComponent('Miss')
export const Multilocation = createIconComponent('Multilocation')
export const Onelocation = createIconComponent('Onelocation')
export const PaypalLogo = createIconComponent('PaypalLogo')
export const MotionDetection = createIconComponent('MotionDetection')
export const NightVision = createIconComponent('NightVision')
export const NoDrill = createIconComponent('NoDrill')
export const Pause = createIconComponent('Pause')
export const PeelAndStick = createIconComponent('PeelAndStick')
export const PencilAndRuler = createIconComponent('PencilAndRuler')
export const PersonRunning = createIconComponent('PersonRunning')
export const PersonWithHardHat = createIconComponent('PersonWithHardHat')
export const Phone = createIconComponent('Phone')
export const PhoneOutgoing = createIconComponent('PhoneOutgoing')
export const PhoneRinging = createIconComponent('PhoneRinging')
export const PinterestLogo = createIconComponent('PinterestLogo')
export const Play = createIconComponent('Play')
export const PlugWire = createIconComponent('PlugWire')
export const Plus = createIconComponent('Plus')
export const PoliceCar = createIconComponent('PoliceCar')
export const PoliceLight = createIconComponent('PoliceLight')
export const PoliceLogo = createIconComponent('PoliceLogo')
export const Pound = createIconComponent('Pound')
export const PrivacyShutter = createIconComponent('PrivacyShutter')
export const Profile = createIconComponent('Profile')
export const ProInstall = createIconComponent('ProInstall')
export const RedSiren = createIconComponent('RedSiren')
export const SearchOutlineIcon = createIconComponent('SearchOutlineIcon')
export const SecretAlerts = createIconComponent('SecretAlerts')
export const SecureCheckmarkIcon = createIconComponent('SecureCheckmarkIcon')
export const SecureHome = createIconComponent('SecureHomeIcon')
export const SecureHomeIcon = createIconComponent('SecureHomeIcon')
export const ShieldAboveHand = createIconComponent('ShieldAboveHand')
export const ShieldCheck = createIconComponent('ShieldCheck')
export const ShippingTruck = createIconComponent('ShippingTruck')
export const SimpleSetup = createIconComponent('SimpleSetup')
export const SimpliCheckBlue = createIconComponent('SimpliCheckBlue')
export const SimpliCheckDark = createIconComponent('SimpliCheckDark')
export const SimpliCheckGold = createIconComponent('SimpliCheckGold')
export const SimpliSafeAppIcon = createIconComponent('SimpliSafeAppIcon')
export const SimpliSafeLogo = createIconComponent('SimpliSafeLogo')
export const SimpliSafeLogoBlack = createIconComponent('SimpliSafeLogoBlack')
export const SimpliSafeLogoDark = createIconComponent('SimpliSafeLogoDark')
export const SimpliSafeLogoWhite = createIconComponent('SimpliSafeLogoWhite')
export const Spotlight = createIconComponent('Spotlight')
export const Siren = createIconComponent('Siren')
export const SmartMotion = createIconComponent('SmartMotion')
export const Star = createIconComponent('Star')
export const Suitcase = createIconComponent('Suitcase')
export const SupportIcon = createIconComponent('SupportIcon')
export const TwitterLogo = createIconComponent('TwitterLogo')
export const TwoWayAudio = createIconComponent('TwoWayAudio')
export const Verification = createIconComponent('Verification')
export const Video = createIconComponent('Video')
export const Warning = createIconComponent('Warning')
export const WarningBlack = createIconComponent('WarningBlack')
export const WeatherResistant = createIconComponent('WeatherResistant')
export const Wired = createIconComponent('Wired')
export const Wireless = createIconComponent('Wireless')
export const X = createIconComponent('Cancel')
export const XmarkHollow = createIconComponent('XmarkHollow')
export const XmarkLightGray = createIconComponent('XmarkLightGray')
export const XSolidIcon = createIconComponent('XSolidIcon')
export const YoutubeLogo = createIconComponent('YoutubeLogo')
export const Wifi = createIconComponent('Wifi')
export const CheckCircleSolid = createIconComponent('CheckCircleSolid')
export const SmallX = createIconComponent('SmallX')
export const Zoom = createIconComponent('Zoom')
