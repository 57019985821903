import { z } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import type { ZodObject, ZodRawShape } from 'zod'
import '@ecomm/ts-reset'

const jsonSchema = z.object({
  content: z.object({
    internal: z.object({
      content: z.string()
    })
  })
})

type ParseJSONDataOptions = {
  readonly data: Record<string, unknown>
  readonly key?: string
  readonly parser?: ZodObject<ZodRawShape>
}
type ParsedData<T> = T extends undefined ? string : T

export function parseJSONDataFromContentful<T>({
  data,
  key,
  parser
}: ParseJSONDataOptions): ParsedData<T> {
  // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
  return pipe(
    jsonSchema.parse(key ? data[key] : data),
    parsedData => parsedData.content.internal.content,
    finalShape =>
      parser ? parser.parse(JSON.parse(finalShape)) : JSON.parse(finalShape)
  )
}
